
<template>
  <div>
    <Filters
      @find-day="dayInfo"
      :isFetchExpenses="isLoading"
      :isLoadingExcel="isLoadingExcel"
    />
   <br>
    <TableExpenses
      v-if="daysListTable"
      :daysList="daysListTable"
      :parameterInfo="parameterInfoList"
    />
    <b-alert show variant="warning" class="p-1 text-center" v-if="isLoading"> Buscando ingresos y gastos <b-spinner small/> </b-alert>
  </div>
</template>

<script>
import { formatDateOnly,daysMonths } from "@/helpers/helpers";
import { mapActions, mapState, mapMutations, mapGetters } from "vuex";
import TableExpenses from '@/modules/fivesClub/components/profit/incomeExpenses/TableExpenses'
import Filters from '@/modules/fivesClub/components/profit/incomeExpenses/Filters'
export default {
  components:{
    TableExpenses,
    Filters
  },
  data() {
    return {
      daysListTable:null,
      parameterInfoList:null,
      isLoading:false,
      isLoadingExcel:false
    };
  },
  async mounted() {
 
  },
  computed: {
  ...mapState("fivesClubProfit", ['expenses']),
  },
  methods: {
  ...mapMutations('fivesClubProfit', ['setExpenses']),
  ...mapActions('fivesClubProfit', ['fetchExpensesList','fetchExpensesExcel']),
    formatDateInfo(fecha, locale) {
      return formatDateOnly(fecha, locale);
    },
    async dayInfo(payload) {
      if (!payload?.getExcel){
        this.daysListTable=null,
        this.isLoading=true
        const expenses = await this.fetchExpensesList(payload)
        this.setExpenses(expenses)
        this.daysListTable= daysMonths(payload)
        this.parameterInfoList=payload
        this.isLoading=false
      } else {
        this.isLoadingExcel=true
        await this.fetchExpensesExcel(payload)
        this.isLoadingExcel=false
      }
    },
  },
};
</script>


<style lang="scss" >
@media (max-width: 1280px) {
  .mb-0.table-responsive {
    max-height: 369px;
  }
  .table-responsive th {
    padding: 10px 10px 10px 10px !important;
    font-size: 10px;
  }
  .table-responsive th div {
    font-size: 10px;
  }
  .table-responsive td {
    padding: 5px !important;
    font-size: 11px;
  }
  .buttonInfo button {
    padding: 9px;
  }

  .buttonInfo {
    display: table-cell;
  }
}
</style>

<template>
  <b-card>
    <b-tabs>
      <b-tab active>
        <template #title>
          <span class="d-none d-sm-inline">Ingresos y gastos</span>
        </template>
        <ListExpenses/>
      </b-tab>
    </b-tabs>
  </b-card>
</template>

<script>
import { mapActions,mapState,mapMutations } from "vuex";
import ListExpenses from '@/modules/fivesClub/components/profit/incomeExpenses/ListExpenses'
export default {
   async mounted() {
    if(this.rentalPoolFilter.length!=0)return false
      let rentalPool= await this.fetchGetRentalPoolFilter();
      this.setRentalPoolFilter(rentalPool)
      
  },
  components:{
    ListExpenses
  },
  computed: {
  ...mapState("fivesClubProfit", ['rentalPoolFilter']),
  },
   methods: {
    ...mapMutations('fivesClubProfit', ['setRentalPoolFilter']),
    ...mapActions('fivesClubProfit', ["fetchGetRentalPoolFilter"]),
  },
};
</script>
